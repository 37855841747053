import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/Layout/Layout"
import SEO from "../components/SEO/SEO"
import PostPreview from "../components/posts/PostPreview/PostPreview"

const BooksPage: React.FC<{
  data: Queries.BooksPostPreviewQuery
}> = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Backpacking"
        description="Posts about backpacking including trip reports, images, and backpacking knowledge."
        image="https://cdn.sanity.io/images/zlybple6/production/d8cb3636d115043cbb44e25dc4fc3818d4a90415-2049x1536.jpg?w=2049&h=1536&auto=format"
        article={false}
      />
      <div
        style={{
          maxWidth: `1000px`,
          margin: `auto`,
          padding: `56px 32px`,
          background: `radial-gradient(circle, rgba(235,235,235,1) 0%, rgba(255,255,255,1) 100%)`,
        }}
      >
        <h1>Posts about Backpacking</h1>
        <div
          style={{
            marginBottom: `56px`,
            display: `flex`,
            flexDirection: `column`,
          }}
        >
          {data?.allSanityPost?.edges.map(({ node }) => {
            return <PostPreview node={node} />
          })}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query BackpackingPostPreview {
    allSanityPost(
      sort: { order: DESC, fields: publishedAt }
      filter: {
        categories: { elemMatch: { title: { in: ["nature", "progress"] } } }
        tags: { elemMatch: { name: { eq: "backpacking" } } }
      }
    ) {
      edges {
        node {
          ...PostPreviewDetails
        }
      }
    }
  }
`

export default BooksPage
